import React from 'react'
import './LinksGrid.css'

function LinksGrid() {
  return (
    <div className='acco-links'>
    <div className="acco-grid">
      <a href='http://rinforconta.blogspot.com/' alt='blogue' target='_blank' rel='noreferrer'><button className="a-item">
      <p>Blogue</p>
      </button></a>
      <a href='https://www.portaldasfinancas.gov.pt/pt/home.action' alt='AT' target='_blank' rel='noreferrer'><button className="a-item">
      <p>AT</p>
      </button></a>
      <a href='https://faturas.portaldasfinancas.gov.pt/home.action' alt='e-fatura' target='_blank' rel='noreferrer'><button className="a-item">
      <p>e-fatura</p>
      </button></a>
      <a href='https://ec.europa.eu/taxation_customs/vies/#/vat-validation' alt='VIES' target='_blank' rel='noreferrer'><button className="a-item">
      <p>VIES</p>
      </button></a>
      <a href='https://www.seg-social.pt/inicio' alt='Segurança Social' target='_blank' rel='noreferrer'><button className="a-item">
      <p>Segurança Social</p>
      </button></a>
      <a href='https://ccclix.occ.pt/' alt='CCCLIX.occ.pt' target='_blank' rel='noreferrer'><button className="a-item">
      <p>CCCLIX.occ.pt</p>
      </button></a>
      <a href='https://portal.occ.pt/' alt='OCC' target='_blank' rel='noreferrer'><button className="a-item">
      <p>OCC</p>
      </button></a>
      <a href='https://eportugal.gov.pt/' alt='IRN' target='_blank' rel='noreferrer'><button className="a-item">
      <p>IRN</p>
      </button></a>
    </div>
   </div>
  )
}

export default LinksGrid;