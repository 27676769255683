import React from 'react';
import { MdLocalHotel, MdManageAccounts, MdHouse, MdComputer } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import AnimatedFromDirection from "../../components/Animations/AnimatedFromDirection";
import "./Home.css"
import LateralNav from '../../components/LateralNav/LateralNav';

function Home() {
  const values = ["Transparência", "Competência", "Inovação", "Ética", "Responsabilidade"];

  return (
    <div className='home'>

    {/* Main Image and Text  */}
    <div className='image-home'>
    <div className='text-home'>
    <div className='navEbuttons'>
    <div className="text-1">
    <h1 className='home-name'>Rogério Fernandes & Gonçalves Lda.</h1>
    <h3>Soluções Globais</h3>
  
    </div>
    <LateralNav/>
    </div>
    </div>
    </div>

    {/* Services Section */}
    <AnimatedFromDirection direction={'left'} duration={1500}>
    <div className='home-services'>

    <div className="main">
    <h4>NOSSOS SERVIÇOS <div className="roller">
    <span id="rolltext">
    Contabilidade
    Informática
    Imóveis & AL
    </span>
    </div>
    </h4>
    
    </div>

    <div className="home-intro">
    <p>Estamos preparados para atender às suas necessidades em cada uma destas áreas. Se procura um suporte contabilístico confiável, soluções tecnológicas eficientes, gestão profissional de propriedades ou serviços completos de arrendamento local, estamos aqui para ajudar.</p> 
    </div>
    <div className='home-list'>
      <a href="/contabilidade">
      <button><MdManageAccounts className="react-icon" id='ic-rent' /></button>
      <span>Contabilidade</span>
      </a>
      <a href="/informatica">
      <button><MdComputer className="react-icon" id='ic-house' /></button>
      <span>Informática</span>
      </a>
      <a href="/alojamento">
      <button><MdLocalHotel className="react-icon" id='ic-al'/></button>
      <span>Alojamento Local</span>
      </a>
      <a href="/imoveis">
      <button><MdHouse className="react-icon" id='ic-house'/></button>
      <span>Compra & Venda de Imóveis</span>
      </a>
    </div>
    </div>
    </AnimatedFromDirection>

    {/* Mission Section */}
    <div className="missionValues">
    <div className='home-mission' id='mission'>
      <h4>A NOSSA MISSÃO</h4>
      <p style={{ fontFamily: 'Playfair Display, serif' }}>" Ser parte integrante dos projetos e negócios dos nossos clientes e parceiros, ao prestar um serviço de contabilidade e apoio à gestão de forma diferenciada, oferecendo soluções inovadoras e tecnológicas."</p>
    </div>

        {/* Values Section */}
        <div className='margin-values'></div>
          <div className="home-values">
            <div className="h-values">
              <h4>VALORES:</h4>
              <p>Partilhamos um conjunto de valores e princípios que se refletem nos nossos comportamentos, atitudes e tomadas de decisão</p>
            </div>
            <ul id="ul-values">
            {values.map((value, index) => (
            <li id="li-values" key={index} >
                {value}
            </li>
            ))}
            </ul>
            
          </div>
          <a  className="whats-app" href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel='noreferrer'>
    <FaWhatsapp />
    </a>
    </div>
  
    </div>

  )
}

export default Home