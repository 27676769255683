import React from 'react'
import LocalCarousel from '../../components/Carousel/Carousel'
import { FaWhatsapp } from 'react-icons/fa';
import CardsAl from '../../components/CardsAl/CardsAl';
import { Button } from '../../components/Buttons/Buttons';
import { MdLocalHotel } from "react-icons/md";
import AnimatedFromDirection from '../../components/Animations/AnimatedFromDirection';
import './ServicesAccommodation.css'

function ServicesAccommodation() {
  return (

    <div>
    <div className='acco-container'>
    <h1 id='al-intro'>Alojamento Local</h1>
      <div className='acco-carousel'>
      </div>
      </div>
        <LocalCarousel />
        <div className='cards-al'>
        <AnimatedFromDirection direction={'left'} duration={1500}>
        <div className='al-text'>
        <div id='al-icon-con'>
        <MdLocalHotel id='al-icon'/>
        </div>
       <p>O alojamento local é uma excelente opção para aqueles que procuram acomodações temporárias durante as suas viagens ou estadias. Oferecemos uma variedade de alojamentos locais, como apartamentos, casas e quartos disponíveis para aluguer.</p>
       </div>
        </AnimatedFromDirection>
        <div className='al-houses'>
        <h4>Disponíveis</h4>
        </div>
        <CardsAl />
        </div>
        <div className='rent-contact'>
    <a href="/contacto"><Button>FALE CONNOSCO</Button></a>
        <a  className="whats-app" href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel='noreferrer'>
    <FaWhatsapp />
    </a>
     </div>  
    </div>
  )
}

export default ServicesAccommodation