import React from 'react';
import './App.css';
import Home from './pages/Home/Home';
import NavbarHome from './components/NavbarHome/NavbarHome';
import Footer from './components/Footer/Footer';
import ServicesAccount from './pages/ServicesAccount/ServicesAccount';
import ServicesRent from './pages/SevicesRent/ServicesRent';
import ServicesAccommodation from './pages/ServicesAccommodation/ServicesAccommodation';
import ServicesInformatics from './pages/ServicesInformatics/ServicesInformatics';
import Contact from './pages/Contact/Contact';
import Scroll from './components/Scroll/Scroll';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound/NotFound';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
        <>
        <NavbarHome />
        <Scroll />
        <Home />
        <Footer />
        </>
        }/>
        <Route path="contabilidade" element={
        <>
        <NavbarHome />
        <Scroll />
        <ServicesAccount />
        <Footer />
        </>
        }/>
        <Route path="imoveis" element={
        <>
        <NavbarHome />
        <Scroll />
        <ServicesRent />
        <Footer/>
        </>  
        }/>
        <Route path="alojamento" element={
        <>
        <NavbarHome />
        <Scroll />
        <ServicesAccommodation />
        <Footer/>
        </>  
        }/>
        <Route path='informatica' element={
        <>
        <NavbarHome />
        <Scroll />
        <ServicesInformatics />
        <Footer/>
        </>  
        } />
        <Route path="contacto" element={
         <>
        <NavbarHome />
        <Scroll />
        <Contact />
        <Footer/>
        </> 
        }/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
