import styled from 'styled-components';

const Button = styled.button`
  color: black;
  background-color: orange;
  font-size: 1em;
  margin: 1em;
  padding: 1em 2em;
  border: 2px solid orange;
  border-radius: 0.2em;
  transition: all 0.5s ease-out;
  fontFamily: 'Lato', sans-serif;



  &:hover {
    background-color: #e89a0a;
    border: 1px solid  #e89a0a;
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 0 5px rgba(0, 0, 1, 0.5);  

  }

  &:active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);  
    color: white;
    background-color: grey;
    }

    @media (max-width: 700px) {
    padding: 0.5em 1.5em;
    &:active {  
    color: white;
    background-color: grey;
    }
  }
`;

/* A new button */

const TomatoButton = styled(Button)`
  color: blue;
  border-color: navy;
`;


export { Button, TomatoButton };
