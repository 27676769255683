import React, { useRef, useState } from 'react'
import Email from '../Email/Email';
import emailjs from '@emailjs/browser';
import './ContactForm.css'

//Important - change the credential or email at emailJS Website!

const ContactForm = () => {
  const maps = 'google reference'  
  const form = useRef();
  const [formStatus, setFormStatus] = useState(false)
  
  const onSubmit = (e) => { 
    e.preventDefault();
    setFormStatus(true)
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID, 
      process.env.REACT_APP_TEMPLATE_ID, 
      form.current, 
      process.env.REACT_APP_PUBLIC_KEY,
      )
    .then((result) => {
        console.log(result.text);
        setFormStatus(false);
        form.current.reset();
    }, (error) => {
        console.log(error.text);
        setFormStatus(false);
    });
  }

  return (
    <div className="contact-container">
    <div className='form-container'>
      <h2>Formulário de Contacto</h2>
      <form ref={form} onSubmit={onSubmit} className='contact-form'>
      
          <input type="text" name="user_name" placeholder="Nome" required />
          <input type='email' name="user_email" placeholder="Email" required />

          <select id="topic" name="topic">
            <option value=""></option>
            <option value="Contabilidade e Informática">Contabilidade e Informática</option>
            <option value="Gestão de Imóveis">Gestão de Imóveis</option>
            <option value="Alojamento Local">Alojamento Local</option>
            <option value="Outros">Outros</option>
          </select>

          <textarea type='text' name="message" cols='30' rows='10' placeholder="Conte nos como podemos ajudar..." required />
          <div className='btnsForm'>
          <button type="submit" value="Send" id="submit" disabled={formStatus}>{formStatus ? 'Enviando...' : 'Enviar'}</button>
          <Email href="mailto:rinforconta@gmail.com" />
          </div>
      </form>
      </div>
      <div className='map'>
        <iframe title={maps} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5948.132641091696!2d-6.7704685117545615!3d41.80533170792171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3a49ef22617d6f%3A0x99ff5fb33d7453fe!2sRog%C3%A9rio%20Fernandes%20%26%20Gon%C3%A7alves%2C%20Lda.!5e0!3m2!1spt-BR!2spt!4v1690570674086!5m2!1spt-BR!2spt" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />        
      </div>
    </div>
  )
}
export default ContactForm