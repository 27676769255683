import React from 'react';
import './NotFound.css'

const NotFound = () => {
  return (
    <div className='error'>
    <div className='not-found'>
      <h1 id='fourofour'>404</h1>
      <h1>Página não encontrada</h1>
      <p id='err-p'>Parece que o conteúdo não está disponível! Volte para a página:</p>
      <a href='/'><p className='err'><span>.</span>Rinforconta<span id='span2'>.</span></p></a>
    </div>
    </div>
  );
};

export default NotFound;