/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import ContactForm from '../../components/ContactForm/ContactForm'
import './Contact.css'
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineLocationOn } from "react-icons/md"

function Contact() {

  return (
    <>
    <div className='intro contact'>
     <h1>Contacto</h1>
     <br />
      <p id='p-1'>Independentemente do assunto, a nossa equipa está sempre disponível para responder às suas questões, fornecer informações adicionais e ouvir as suas sugestões. Estamos empenhados em responder o mais rapidamente possível e prestar a assistência necessária.</p> 
      <p id='p-2'><b>Não hesite em entrar em contacto connosco. Estamos aqui para ajudar!</b></p>    
      </div>
     <div className='contact-grid'>
     <div className='contact-items'>  
     <FaPhoneAlt className='icon-c'/>
     <h4>Telefone</h4>
     <a className='dados-c' href="tel:+351 273 332 187" target="_blank" rel='noreferrer'>273 332 187 (Rede fixa Nacional)</a>
     <br />
     <MdOutlineLocationOn className='icon-c'/>
     <h4>Morada</h4>
     <a className='dados-c' href="https://goo.gl/maps/uqTzNU1FczLXV4WJ6" target="_blank" rel='noreferrer'>R. Alexandre Herculano, 116 (Galerias Tuela) Loja 9 | 5300-075, Bragança - PT</a>
     <br />
     <FaWhatsapp className='icon-c'/>
     <h4>WhatsApp</h4>
     <a className='dados-c' href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel='noreferrer'>+351 962738206</a>
     <br />
     <AiOutlineMail className='icon-c'/>
     <h4>E-mail</h4>
     <a className='dados-c' href="mailto:rinforconta@gmail.com" target="_blank" rel='noreferrer'>rinforconta@gmail.com</a>
     </div>
     <div className='contact-form'>
     <ContactForm/> 
    </div>
    </div>

    <a  className="whats-app" href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel='noreferrer'>
    <FaWhatsapp />
    </a>
   
   </>
  )
}

export default Contact