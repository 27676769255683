import React, { useEffect, useState } from 'react';
import sanityClient from '../../client';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import { BiBath, BiBed, BiRuler } from 'react-icons/bi';
import { MdOutlineLocationOn } from 'react-icons/md';
import ModalCard from '../../components/Modal/Modal'
import './Card.css';

function HouseCard() {
  const [houses, setHouses] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState(null); // Add selectedHouse state variable


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await sanityClient.fetch(
          `*[_type == 'house']{"images": images[]{..., "url": asset->url}, title, description, rooms, baths, cost, area, location, mapLink, airbnb, booking}`
        );
        console.log('House Data:', result);
        setHouses(result);
      } catch (error) {
        console.error('Error fetching data from Sanity:', error);
      }
    };

    fetchData();
  }, []);

  const handleShowModal = (house) => {
    setSelectedHouse(house); 
  };

  return (
    <div className="cards-background">
      <Row xs={1} md={3} className="card-row">
        {houses.map((house, idx) => (
          <Col key={idx}>
            <Card id="cards">
              <Row>
                <Col>
                  <Carousel interval={null} indicators={houses.length > 1} prevIcon={null} nextIcon={null} className="carousel-container">
                    {house.images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <Card.Img src={image.url} alt={house.title} className="carousel-image" />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  {houses.length >= 1 && (
                    <div id="carousel-indicators">
                      {[...Array(house.images.length)].map((_, index) => (
                        <div key={index} className="carousel-indicator" />
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Card.Body id="card-bd">
                <Card.Header id="card-header">{house.title}</Card.Header>
                <Card.Text id="card-desc">{house.description}</Card.Text>
                <Card.Footer>
            {house.rooms && house.baths && (
            <Card.Text id="card-footer">
              <BiBed className="card-icon" /> {house.rooms} {house.rooms === 1 ? 'quarto' : 'quartos'}{' '}
              <BiBath className="card-icon" /> {house.baths} {house.baths === 1 ? 'casa de banho' : 'casas de banho'}
            </Card.Text>
            )}
          {house.area && (
          <Card.Text id="card-footer">
            <BiRuler className="card-icon" /> {house.area}m² área bruta
          </Card.Text>
          )}
          <Card.Text id="card-footer">
            <MdOutlineLocationOn className="card-icon" /> {house.location}
          </Card.Text>
        </Card.Footer>
                <div className='btn-container'>
                <button variant="primary" className='modal-btn' onClick={() => handleShowModal(house)}>
                  +
                </button>
               </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {selectedHouse && <ModalCard house={selectedHouse} onClose={() => setSelectedHouse(null)} />}
    </div>
  );
}

export default HouseCard;


