import React from 'react'
import './LateralNav.css'
import { MdLocalHotel, MdManageAccounts, MdHouse, MdComputer } from "react-icons/md";


function LateralNav() {
  return (
    <div>
    <div className='services-btn'>
    <div className='align-btns'>
    <button className='s-btn'><MdManageAccounts id='btn-ico'/><a href='/contabilidade'><p class="button-text">Contabilidade</p></a></button>
    <button className='s-btn'><MdComputer id='btn-ico'/><a href='informatica'><p class="button-text">Informática</p></a></button>
    <button className='s-btn'><MdLocalHotel id='btn-ico'/><a href='alojamento'><p class="button-text">Alojamento Local</p></a></button>
    <button className='s-btn'><MdHouse id='btn-ico'/><a href='/imoveis'><p class="button-text">Compra e Venda de Imóveis</p></a></button>
    </div>
    </div>
    </div>
  )
}

export default LateralNav