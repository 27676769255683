import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import sanityClient from '../../client';
import defaultImage from '../../assets/images/braganca.jpeg';

function LocalCarousel() {
  const [carouselData, setCarouselData] = useState([]);

  useEffect(() => {
    const fetchCarouselData = async () => {
      try {
        const response = await sanityClient.fetch(`*[_type == 'carousel']{"image": image.asset->url, description}`);
        console.log('Carousel Data:', response);
        setCarouselData(response);
      } catch (error) {
        console.error('Error fetching carousel data:', error);
      }
    };

    fetchCarouselData();
  }, []);

  return (
    <div className='carr'>
      <Carousel slide={true} controls={false}>
        {carouselData.map((item, index) => {
          console.log('Carousel Item:', item);
          return (
            <Carousel.Item key={index}>
            <img
              src={item.image || defaultImage}
              alt="Local landscape"
              style={{
              objectFit: 'cover',
              width: '100vw',
              height: '700px'
              }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default LocalCarousel;

