import React from 'react';
import './Catalog.css';

const Catalogue = () => {
  return (
    <div className="container main_container">
      <div className="row" id='catolog-row'>
        <div className="col-xs-13" style={{ textAlign: 'right' }}>
          <div className="caixaCatalogo">
              <img
                src="https://www.cpcdi.pt/Storage/imagens-catalogo/abysm_logo.png"
                className="imagemCatalogo"
                alt="Abysm"
              />
          </div>
          <div className="caixaCatalogo">
              <img
                src="https://www.cpcdi.pt/Storage/imagens-catalogo/acer_logo.jpg"
                className="imagemCatalogo"
                alt="Acer"
              />
          </div>
          <div className="caixaCatalogo">
              <img
                src="https://www.cpcdi.pt/Storage/imagens-catalogo/amd.gif"
                className="imagemCatalogo"
                alt="AMD"
              />
          </div>
          <div className="caixaCatalogo">
              <img
                src="https://www.cpcdi.pt/Storage/imagens-catalogo/apc_logo.jpg"
                className="imagemCatalogo"
                alt="APC"
              />
          </div>
          <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/apple_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Apple"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/asrock_logo.png" 
                    className="imagemCatalogo"
                    alt="Asrock"
                   />
                </div>
            <div className="caixaCatalogo">
                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/asusweblogo.jpg" 
                    className="imagemCatalogo"
                    alt='ASUS'
                   />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/beeverycreative_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="beeverycreative"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/benq.gif" 
                    className="imagemCatalogo" 
                    alt="benq"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/birch.jpg" 
                    className="imagemCatalogo"
                    alt="birch" 
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/bq.jpg" 
                    className="imagemCatalogo" 
                    alt="bg"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/brother.jpg" 
                    className="imagemCatalogo" 
                    alt="brother"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/broyen_logo.jpeg" 
                    className="imagemCatalogo" 
                    alt="broyen"        
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/canon.gif" 
                    className="imagemCatalogo" 
                    alt="Canon"        
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/citypos_logo.png" 
                    className="imagemCatalogo" 
                    alt="citypos_logo"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/di_componentes.gif" 
                    className="imagemCatalogo" 
                    alt="di"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/conceptronic.jpg" 
                    className="imagemCatalogo" 
                    alt="conceptronic"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/coolermaster_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="coolermaster"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/corsair_logo.png" 
                    className="imagemCatalogo" 
                    alt="corsair"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/dlink.jpg" 
                    className="imagemCatalogo" 
                    alt="dlink"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/logo_datalogic.jpg" 
                    className="imagemCatalogo" 
                    alt="Datalogic"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/devia_logo.png" 
                    className="imagemCatalogo"
                    alt="Devia"
                     />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/di_logo.gif" 
                    className="imagemCatalogo" 
                    alt="DI"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/dynabook (toshiba)_logo.png" 
                    className="imagemCatalogo" 
                    alt="Toshiba"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/eaton.jpg" 
                    className="imagemCatalogo" 
                    alt="Eaton"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/edimax_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Edimax"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/epson.gif" 
                    className="imagemCatalogo" 
                    alt="Epson"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/equip.jpg" 
                    className="imagemCatalogo" 
                    alt="Equip"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/ewent_logo.jpeg" 
                    className="imagemCatalogo" 
                    alt="Ewent"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/fec.jpg" 
                    className="imagemCatalogo" 
                    alt="FEC"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/genius.gif" 
                    className="imagemCatalogo" 
                    alt="Genius"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/goodram_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="GoodRAM"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/honeywell_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Honeywell_Metrologic"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/hopestar_logo.png" 
                    className="imagemCatalogo" 
                    alt="HOPESTAR"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/hpi_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="HP"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/hpe_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="HPE"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/hpe aruba_logo.png" 
                    className="imagemCatalogo" 
                    alt="hpe_Aruba"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/huawei_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Huawei"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/ibm_logo.png" 
                    className="imagemCatalogo" 
                        alt="IBM"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/identive.jpg" 
                    className="imagemCatalogo" 
                    alt="Identive"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/image10.gif" 
                    className="imagemCatalogo" 
                    alt="Intel"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/iris_logo.png" 
                    className="imagemCatalogo" 
                    alt="IRIS"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/kingston.gif" 
                    className="imagemCatalogo" 
                    alt="Kingston"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/kingston_valueram_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Kingston_ValueRam"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/krom_logo.png" 
                    className="imagemCatalogo" 
                    alt="Krom"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/lebooo_logo.png" 
                    className="imagemCatalogo" 
                        alt="Lebooo"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/lenovo_16.jpg" 
                    className="imagemCatalogo" 
                        alt="Lenovo"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/levelone_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="LevelOne"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/lg.gif" 
                    className="imagemCatalogo" 
                    alt="LG"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/mercusys_logo.png" 
                    className="imagemCatalogo" 
                    alt="Mercusys"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/microsoft.jpg" 
                    className="imagemCatalogo" 
                    alt="Microsoft"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/microsoft_oem.gif" 
                    className="imagemCatalogo" 
                    alt="Microsoft_OEM"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/msi_logo.png" 
                    className="imagemCatalogo" 
                        alt="MSI"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/logo_napofix.jpg" 
                    className="imagemCatalogo" 
                    alt="Napofix"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/ngs.gif" 
                    className="imagemCatalogo" 
                    alt="NGS"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/nikon.gif" 
                    className="imagemCatalogo" 
                    alt="Nikon"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/norton lifelock_logo.png" 
                    className="imagemCatalogo" 
                    alt="Norton"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/nox_logo.png" 
                    className="imagemCatalogo" 
                    alt="NOX"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/oki.gif" 
                    className="imagemCatalogo" 
                    alt="OKI"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/olympus.gif" 
                    className="imagemCatalogo" 
                    alt="Olympus"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/panda_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Panda"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/philips_logo.jpeg" 
                    className="imagemCatalogo" 
                    alt="Philips"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/plusscreen.gif" 
                    className="imagemCatalogo" 
                    alt="Plusscreen"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/portdesigns.jpg" 
                    className="imagemCatalogo" 
                    alt="Port_Designs"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/razer_logo.png" 
                    className="imagemCatalogo" 
                    alt="RAZER"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/s3plus_logo.png" 
                    className="imagemCatalogo"
                     alt="s3plus"
                     />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/samsung.jpg" 
                    className="imagemCatalogo" 
                    alt="Samsung"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/sandisk_logo.png" 
                    className="imagemCatalogo" 
                    alt="SanDisk"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/scantech_logo.png" 
                    className="imagemCatalogo" 
                    alt="Scantech"
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/seagate_logo.png" 
                    className="imagemCatalogo" 
                    alt="Seagate"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/simplee_logo.png" 
                    className="imagemCatalogo" 
                    alt="Simplee"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/sitten_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Sitten"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/sony_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Sony"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/targus_logo.png" 
                    className="imagemCatalogo" 
                    alt="Targus"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/tem_logo.png" 
                    className="imagemCatalogo" 
                    alt="TEM"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/tomtom.jpg" 
                    className="imagemCatalogo" 
                    alt="TOMTOM"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/toshiba_logo.png" 
                    className="imagemCatalogo" 
                    alt="Toshiba"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/tp_link.jpg" 
                    className="imagemCatalogo" 
                    alt="TP-Link"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/transcend_logo.png" 
                    className="imagemCatalogo" 
                    alt="Transcend"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/trust_logo.png" 
                    className="imagemCatalogo" 
                    alt="Trust"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/unykach_logo.png" 
                    className="imagemCatalogo" 
                    alt="UNYKAch"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/viewsonic.gif" 
                    className="imagemCatalogo" 
                    alt="ViewSonic"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/wd_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Western_Digital"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/wingsys_logo.jpeg" 
                    className="imagemCatalogo" 
                    alt="Wingsys"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/xprinter_logo.png" 
                    className="imagemCatalogo" 
                    alt="Xprinter"    
                    />
                </div>
            <div className="caixaCatalogo">

                    <img src="https://www.cpcdi.pt/Storage/imagens-catalogo/zebra_logo.jpg" 
                    className="imagemCatalogo" 
                    alt="Zebra"    
                    />
                </div>
        </div>
      </div>
    </div>
  );
};

export default Catalogue;







