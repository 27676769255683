import React from 'react'
import HouseCard from '../../components/Card/Card'
import AnimatedFromDirection from '../../components/Animations/AnimatedFromDirection';
import { MdHouse } from "react-icons/md";
import { Button } from '../../components/Buttons/Buttons'
import { FaWhatsapp } from 'react-icons/fa';

import './ServicesRent.css'

function ServicesRent() {
  return (
    <div className='rent'>
    <div className='rent-intro1'>
    <div className='intro' id='rent-text'>
    <h1>Compra & Venda de Imóveis</h1>
    </div>
    <br/>
    <div className='rent-ball'>
      <p>Oferecemos um serviço especializado de compra, venda e arrendamento de imóveis de nossa própria carteira. Contamos com uma seleção cuidadosamente escolhida de propriedades disponíveis.
      <br/>
      Nossa equipa está pronta para auxiliá-lo em todo o processo, desde a visita aos imóveis até a negociação e formalização do contrato.</p>
    </div>
    </div>
    <AnimatedFromDirection direction={'right'} duration={1500}>
    <div className='rent-intro'>
     <p>Trabalhamos com transparência e comprometimento para garantir que você encontre a propriedade ideal que atenda às suas necessidades e desejos</p>
     <MdHouse className='react-icon' id='icon-rent'/>
    </div>
    </AnimatedFromDirection>
    <div className='rent-houses'>
    <h4>Imóveis Disponíveis</h4>
    </div>
    <HouseCard />

    <div className='rent-contact'>
    <a href="/contacto"><Button>FALE CONNOSCO</Button></a>
    <a  className="whats-app" href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel='noreferrer'>
    <FaWhatsapp />
    </a>
    
    </div>

    </div>
  )
}

export default ServicesRent