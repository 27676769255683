import React from 'react'
import { Button } from '../../components/Buttons/Buttons'
import AnimatedFromDirection from '../../components/Animations/AnimatedFromDirection'
import imageA from '../../assets/images/account1.jpeg'
import LinksGrid from '../../components/LinksGrid/LinksGrid'
import { FaWhatsapp } from 'react-icons/fa';
import { MdManageAccounts } from "react-icons/md";
import AccountantList from '../../components/AccountantList/AccountantList'

import './ServicesAccount.css'

function ServicesAccount() {
  return (
    <div className='acco-container'>
    <div id='acco'>
    {/* <h1>Contabilidade</h1> */}
    </div>
    <div className='acco-section-wrap'>
    <h1>Contabilidade</h1>
    <AnimatedFromDirection direction={'left'} duration={1500}>
    <div className='acco-section'>
    <div className='acco-back1'>
      <h3>Sobre</h3>
      <p>Somos uma empresa de prestação de serviços de contabilidade e venda de hardware/software profissional, consultoria fiscal e gestão.</p>
    </div>
    </div>
    </AnimatedFromDirection>
    </div>
<h3 id="serv">Serviços</h3>
<br />

<div className='acco-services'>
<AccountantList />
</div>

   <div className='links-u'>
   <h3>Links Úteis</h3>
   <div className='acc-grid'>
   <LinksGrid/>
   </div>
   </div>

    <div className='acco-section2'>
    <div className='acco-back2'>
      <p>"Oferecemos um serviço completo e essencial ao desenvolvimento de projetos e negócios dos nossos clientes. Abordamos a organização como um todo, apoiando as áreas de contabilidade, consultoria fiscal e de gestão, recursos humanos e serviços administrativos, imprescindíveis ao crescimento sustentado da mesma."</p>
      <a href="/contacto"><Button>FALE CONNOSCO</Button></a>
    </div>
    </div>
    <div className='acco-section3'>
    <div className='acco-back3'>
    <div className='acco-icon'>
      <div className='acco3-back'>
      <div className='accontant-icon'>
      <span className="icon-container"><MdManageAccounts/></span>
    </div>
      <p id='acco3-text'>Estamos sempre disponíveis em encontrar as melhores soluções para os nossos clientes, mantendo uma relação próxima de forma a sugerir melhorias na gestão e otimização fiscal.</p>
      </div>
      <img src={imageA} alt='itens de contabilidade' className='acco-img'/>
      </div>  
    </div>
    </div>
    <a  className="whats-app" href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel="noopener noreferrer">
    <FaWhatsapp />
    </a>
    <hr/>
    </div>
  )
}

export default ServicesAccount