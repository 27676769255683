import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import { MdOutlineLocationOn, MdOutlineArrowLeft, MdOutlineArrowRight, MdEuro } from 'react-icons/md';
import { TbBrandBooking, TbBrandAirbnb } from 'react-icons/tb'
import { BiBed, BiBath, BiRuler } from "react-icons/bi";
import './Modal.css';
import { House } from "phosphor-react";

function ModalCard({ house, onClose }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  

    const iconStyles = {
      color: 'orange',
      border: '2px solid white',
      backgroundColor: 'white',
      height: '60px',
      fontSize: '60px',
    };

    return (
        <div className="modal-container">
        <div
          className="d-flex align-items-center justify-content-center" id='modal'>
          <Button variant="primary" onClick={handleShow} id='modal-btn'>
            Saber Mais
          </Button>
        </div>
      <Modal show={true} onHide={onClose} className="modal-body" style={{position: 'absolute' }}>
        <Modal.Header closeButton>
          <Modal.Title id='modal-title'><span>.</span>{house.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Carousel interval={null} 
        indicators={house.images.length > 1} 
        prevIcon={house.images.length > 1 && <MdOutlineArrowLeft style={iconStyles} id="carousel-control-prev" />}
        nextIcon={house.images.length > 1 && <MdOutlineArrowRight style={iconStyles} id="carousel-control-next" />}          
        >
        {house.images.map((image, index) => (
            <Carousel.Item key={index}>
              <img src={image.url} alt={house.title} className="modal-image" />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="modal-header-al">
        {house.rooms && (
          <span>
            <BiBed className="card-icon" /> {house.rooms} {house.rooms === 1 ? 'quarto' : 'quartos'}
          </span>
        )}
      {house.baths && (
        <span>
          <BiBath className="card-icon" /> {house.baths} {house.baths === 1 ? 'casa de banho' : 'casas de banho'}
        </span>
      )}

      {house.area && (
        <span>
          <BiRuler className="card-icon" /> {house.area}m² área bruta
        </span>
      )}
    </div>
        <hr />
        <p className="modal-description">{house.description}</p>
        {house.mapLink && (
          <div className='responsive-map-container'>
            <iframe
               title="Embedded Map"
               src={house.mapLink}
               frameBorder={0}
               style={{ border: 0 }}
               allowFullScreen
                />
            </div>
          )}
        <hr />
        <div className='modal-local-rent'>
        <MdOutlineLocationOn id='rent-ico-modal' />{house.location}
        <br />
        {House.cost &&(
        <>
        <MdEuro id='rent-ico-modal'/> {house.cost}
         </>
        )}
       </div>
      </Modal.Body>
        <Modal.Footer>
          <a href='/contacto' target="_blank" rel="noopener noreferrer"><Button variant="primary" onClick={handleClose} id='contact-btn'>
            Entre em Contacto
          </Button></a>
          <a href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel="noopener noreferrer" >
          <Button variant="primary" onClick={handleClose} id='wts-btn'>
            Whatsapp
          </Button></a>
          {house.booking && (
          <a href={house.booking} target="_blank" rel="noopener noreferrer">
         <Button variant="primary" onClick={handleClose} id='booking-btn' title='Booking'>
         <TbBrandBooking />
        </Button>
        </a>
  )}
  {house.airbnb && (
    <a href={house.airbnb} target="_blank" rel="noopener noreferrer" title='Airbnb'>
      <Button variant="primary" onClick={handleClose} id='airbnb-btn'>
       <TbBrandAirbnb/>
      </Button>
    </a>
  )}
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
  
  export default ModalCard;