import React from 'react';
import './Email.css'

const Email = () => {

  return (
    <button  className='emailBtn'><a href="mailto:rinforconta@gmail.com" target="_blank" rel="noopener noreferrer">
      EMAIL
    </a></button>
  );
};

export default Email