import React from 'react';
import "./AccountantList.css";

const servicesData = [
  {
    title: "Contabilidade",
    services: [
      "Contabilidade geral",
      "Recolha de documentação online",
      "Contabilidade analítica",
      "Relatórios financeiros regulares",
      "Contas correntes de fornecedores e de clientes",
      "Consolidação de contas",
      "Fecho de contas e demonstração financeira",
    ],
  },
  {
    title: "Consultoria & Recursos Humanos",
    services: [
      "Apoio relativamente à legislação laboral",
      "Processamento de salários e retenções na fonte.",
      "Estudo analítico do custo com os colaboradores.",
      "Declaração de vencimentos para efeitos de IRS",
    ],
  },
  {
    title: "Fiscalidade",
    services: [
      "Envio de declarações fiscais à AT",
      "Prestação de contas",
      "Elaboração de relatórios fiscais",
      "Planeamento fiscal",
      "Elaboração de declarações estatísticas",
    ],
  },
];

function AccountantList() {
  return (
    <>
      {servicesData.map((serviceGroup, index) => (
          <div key={index} className='col-acco'>
            <h5>{serviceGroup.title}</h5>
            <ul>
              {serviceGroup.services.map((service, serviceIndex) => (
                <li key={serviceIndex}><span>{service}</span></li>
              ))}
            </ul>
        </div>
      ))}
    </>
  );
}

export default AccountantList