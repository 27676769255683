import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from '@react-spring/web';

const AnimatedFromDirection = ({ children, direction }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const element = document.getElementById('animated-from-direction');

      if (element && scrollTop + windowHeight > element.offsetTop) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const animationConfig = {
    opacity: showContent ? 1 : 0,
    transform: showContent ? 'translateX(0)' : `translateX(${direction === 'left' ? '-' : ''}100%)`,
    config: config.slow, // Adjust the speed here
  };

  const animation = useSpring(animationConfig);

  return (
    <animated.div id='animated-from-direction' style={animation}>
      {children}
    </animated.div>
  );
};

export default AnimatedFromDirection;