import React from 'react'
import Catalogue from '../../components/Catalog/Catalog'
import Software from '../../components/Software/Software'
import imageF from '../../assets/images/filosoft.png'
import { Button } from '../../components/Buttons/Buttons'
import AnimatedFromDirection from '../../components/Animations/AnimatedFromDirection'
import { FaWhatsapp } from 'react-icons/fa'
import { MdComputer, MdOutlineMouse } from 'react-icons/md'
import './ServicesInformatics.css'

function ServicesInformatics() {
  return (
<div className='informatica'>
<div className='info-intro'>
<h1 id="info-title">INFORMÁTICA</h1>
<AnimatedFromDirection direction={'right'} duration={1500}>
<div className='info-section'>
<div className='inf-back1'>
<p>Com nossos serviços de informática atendemos as suas necessidades relacionadas à compra e reparação de marcas de computadores e dispositivos eletrónicos. Além disso, oferecemos soluções personalizadas e serviços tercerizados de software para impulsionar o sucesso do seu negócio.</p>
<div className='btn-cont'>
<button className='info-btn-intro'><MdOutlineMouse/><a href="#hardware">HARDWARE</a></button> <button className='info-btn-intro'><MdComputer/><a href='#software'>SOFTWARE</a></button>
</div>
</div>
</div>
</AnimatedFromDirection>

</div>

<div className='info-catalog-software'>
 <h4 id='software'>software</h4>
 <h3>filosoft<span><img src={imageF} alt='filosoft logo' className='logo-software'/></span></h3>
 <br/>
 <p>Soluções baseadas nos serviçoes <spam><a href='https://www.filosoft.pt/' target='_blank' rel="noreferrer">Filosof</a></spam>, um software que oferta soluções de gestão inovadoras, intuitivas e 100% adaptáveis ao mercado empresarial, de forma a satisfazer e superar as expetativas dos clientes.</p>
</div>
 <Software />

<div className='info-catalog'>
<div className='info-catalog-intro'>
<h4 id='hardware'>hardware</h4>
<h3>Catalogo de Marcas</h3>
<p>Disponibilizamos suporte de compra, venda e reparação para um ampla gama de marcas:</p>
</div>
 <Catalogue />   
 <br />
</div>
<div className='inf-final'>
<span className="icon-cont"><MdComputer/></span>
<div className='info-icon'>
      <p>Peça o seu orçamento</p>
      <a href="/contacto"><Button>FALE CONNOSCO</Button></a>

</div>
</div>
    <a  className="whats-app" href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel="noopener noreferrer">
    <FaWhatsapp />
    </a>
</div>
  )
}

export default ServicesInformatics