import React, { useState } from 'react';
import './NavbarHome.css';

function NavbarHome () {

  const [responsive, setResponsive] = useState(false);
  const handleToggle = () => {
    setResponsive(!responsive);
  };
  return (
       <div>
      <div className={`topnav${responsive ? ' responsive' : ''}`} id='nav-primar'>
        <a href="/" className="active" id='logo'><span>.</span>rinforconta</a>
        <a href="/">Home</a>
        <div className="dropdown">
          <button className="dropbtn">Serviços
            <i className="fa fa-caret-down"></i><span id='dropdown-ico'>&nabla;</span>
          </button>
          <div className="dropdown-content">
            <a href="/contabilidade">Contabilidade</a>
            <a href="/informatica">Informática</a>
            <a href="/alojamento">Alojamento Local</a>
            <a href="/imoveis">Compra & Venda de Imóveis</a>
          </div>
        </div>
        <a href="/contacto" id='i-contact'>Contacto</a>
        <a role="button" href="#" className={`icon${responsive ? ' active' : ''}`} onClick={handleToggle}>
          {responsive ? '✕' : '☰'}
        </a>      
     </div>
    </div>
  );
}

export default NavbarHome;

