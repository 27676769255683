import React from 'react'
import { FaFacebookF, FaLinkedin, FaBlogger, FaWhatsapp, FaHome } from 'react-icons/fa';
import { AiOutlineMail } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import logoA from '../../assets/images/LOGO AIP_n.png'
import logoB from '../../assets/images/MateriaisPENHAS_1000x1000px-01.jpg';
import logoC from '../../assets/images/Modelos-Barras-FUNDOS-v04_3logos-FSE.png';
import logoD from '../../assets/images/UB_VF.png';
import logoE from '../../assets/images/Logo MOVE pme.jpg'
import "./Footer.css"

function Footer() {
return (
<>
<MDBFooter style={{ backgroundColor: '#F0F1F2', color: '#D5E5F2' }} className='footer text-center text-lg-start text-muted' id='footer-id'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' id='footer-social'>
        <div className='me-5 d-none d-lg-block'>
        <span>Entre em contacto através das nossas redes sociais</span>
        </div>

        <div className='social-media'>
          <a href='https://www.facebook.com/rinforconta' className='me-4 text-reset' id='footer-hv' target='_blank' rel='noreferrer'>
            <FaFacebookF fab="true" id="facebook-f" />
          </a>
          <a href='/' className='me-4 text-reset' id='footer-hv' target='_blank' rel='noreferrer'>
            <FaLinkedin fab="true" id="linkedin" />
          </a>
          <a href='http://rinforconta.blogspot.com/' className='me-4 text-reset'  id='footer-hv' target='_blank' rel='noreferrer'>
            <FaBlogger fab="true" id="blogue" />
          </a>
        </div>
      </section>

      <section className='foot-items'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4' >
              <h6 className='text-uppercase fw-bold mb-4'>
                <BiWorld icon="gem" className="me-3" />
                rinforconta
              </h6>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Serviços</h6>
              <p>
                <a href='/contabilidade' className='text-reset' id='ci'>
                Contabilidade
                </a>
              </p>
              <p>
                <a href='/informatica' className='text-reset' id='gi'>
                Informática
                </a>
              </p>
              <p>
                <a href='/alojamento' className='text-reset' id='al'>
                Alojamento Local
                </a>
              </p>
                <p>
                <a href='/arrendamento' className='text-reset' id='gi'>
                Compra & Venda de Imóveis
                </a>
              </p>
            </MDBCol>
            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contacto</h6>
              <p>
                <FaHome icon="home" className="me-2" /><a href="https://goo.gl/maps/uqTzNU1FczLXV4WJ6" target="_blank" rel='noreferrer'> Bragança, 5300-075, PT</a>
              </p>
              <p>
                <AiOutlineMail icon="envelope" className="me-3" />
                <a href="mailto:rinforconta@gmail.com" target="_blank" rel='noreferrer'>rinforconta@gmail.com</a>
              </p>
              <p>
                <FaWhatsapp icon="print" className="me-3" /><a href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel='noreferrer'>+351 962 738 206</a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' id="footer-dev" style={{ backgroundColor: 'white' }}>
       <ul className='ulLogo'>
          <li id="li-1"><img src={logoC} alt='logoC' style={{width: '60vw', height: 'auto'}}/><img src={logoB} alt='logoB' style={{width: '15vw', height: 'auto', marginTop: '2.2em'}}/></li>
          <li><img src={logoA} alt='logoA' style={{width: '12vw', height: 'auto'}} /><img src={logoE} alt='logoMove' style={{width: '20vw', height: 'auto', marginLeft: '3em', marginRight: '2em'}}/><img src={logoD} alt='logo' style={{width: '20vw', height: 'auto'}} /></li>
          <li></li>
       </ul>
      </div>
    </MDBFooter>
    <p id="footerRinfo">Rinforconta © . 2023</p> 
</>
  );
}

export default Footer