import { createClient } from '@sanity/client';

const client = createClient({
  projectId: 'x5spih0r',
  dataset: 'production',
  apiVersion: '2021-08-31',
  // other configuration options
  useCdn: false,
});

export default client;