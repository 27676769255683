import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';
import { BsWifi } from 'react-icons/bs';
import { MdEuro, MdBalcony, MdPool, MdPerson, MdOutlineLocationOn, MdElevator, MdOutlineGarage, MdOutlineLocalLaundryService, MdPets, MdOutlineArrowLeft, MdOutlineArrowRight } from 'react-icons/md';
import { Television } from "phosphor-react";
import { BiBed, BiBath } from 'react-icons/bi';
import { TbAirConditioning, TbBrandBooking, TbBrandAirbnb } from 'react-icons/tb';
import './Modal.css';

function ModalCardAl({ al, onClose }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);  
    
    const iconStyles2 = {
      color: '#14213D',
      border: '2px solid white',
      backgroundColor: 'white',
      height: '60px',
      fontSize: '60px',
    };

    return (
        <div className="modal-container">
        <div
          className="d-flex align-items-center justify-content-center" id='modal'>
          <Button variant="primary" onClick={handleShow} id='modal-btn'>
            Saber Mais
          </Button>
        </div>
      <Modal show={true} onHide={onClose} className="modal-body" style={{position: 'absolute' }}>
        <Modal.Header closeButton>
          <Modal.Title id='modal-title'><span>.</span>{al.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Carousel interval={null} 
        indicators={al.images.length > 1} 
        prevIcon={al.images.length > 1 && <MdOutlineArrowLeft style={iconStyles2} id="carousel-control-prev" />}
        nextIcon={al.images.length > 1 && <MdOutlineArrowRight style={iconStyles2} id="carousel-control-next" />}          
        >          
           {al.images.map((image, index) => (
            <Carousel.Item key={index}>
              <img src={image.url} alt={al.title} className="modal-image" />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className='modal-header-al'>
        {al.guests && (
  <span>
    <MdPerson /> {al.guests === 1 ? al.guests + ' hóspede' : al.guests + ' hóspedes'}
  </span>
)}
{al.rooms && (
  <span>
    <BiBed /> {al.rooms === 1 ? al.rooms + ' quarto' : al.rooms + ' quartos'}
  </span>
)}
{al.baths && (
  <span>
    <BiBath /> {al.baths === 1 ? al.baths + ' casa de banho' : al.baths + ' casas de banho'}
  </span>
)}
        </div>
        <p className="modal-description">{al.description}</p>
        <hr/>
        <div className='modal-comm'>
            {al.icon1 && (
            <p>Wifi <BsWifi className='modal-al-icon'/></p>
            )}
            {al.icon2 && (
            <p>Piscina <MdPool className='modal-al-icon'/></p>
            )}
           {al.icon9 && (
           <p>TV Box <Television className='modal-al-icon'/></p>
           )}
           {al.icon3 && (
           <p>Ar Condicionado <TbAirConditioning className='modal-al-icon'/></p>
           )}
           {al.icon4 && (
           <p>Elevador <MdElevator className='modal-al-icon'/></p>
           )}
           {al.icon7 && (
           <p>Máquina de Lavar Roupa <MdOutlineLocalLaundryService className='modal-al-icon'/></p>
           )}
           {al.icon6 && (
           <p>Animais de Estimação <MdPets className='modal-al-icon'/></p>
           )}
           {al.icon5 && (
           <p>Garagem  <MdOutlineGarage className='modal-al-icon'/></p>
           )}
           {al.icon8 && (
           <p>Varanda  <MdBalcony className='modal-al-icon'/></p>
           )}
        </div>
        {/* Additional information */}
        <hr />
        {al.mapLink && (
          <div className='responsive-map-container'>
            <iframe
               title="Embedded Map"
               src={al.mapLink}
               frameBorder={0}
               style={{ border: 0 }}
               allowFullScreen
                />
            </div>
          )}
        <hr />
        <div className='modal-local-al'>
        <MdOutlineLocationOn id='local-ico-modal' />{al.location}
        <br /> 
        {al.cost &&(
        <>
        <MdEuro id='al-ico-modal'/> {al.cost}
         </>
        )}
        </div>
        </Modal.Body>
        <Modal.Footer>
          <a href='/contacto' target="_blank" rel="noopener noreferrer"><Button variant="primary" onClick={handleClose} id='contact-btn'>
            Entre em Contacto
          </Button></a>
          <a href="https://api.whatsapp.com/send?phone=351962738206" target="_blank" rel="noopener noreferrer" >
          <Button variant="primary" onClick={handleClose} id='wts-btn'>
            Whatsapp
          </Button></a>
          {al.booking && (
          <a href={al.booking} target="_blank" rel="noopener noreferrer">
         <Button variant="primary" onClick={handleClose} id='booking-btn'  title='Booking'>
         <TbBrandBooking />
        </Button>
        </a>
        )}
        {al.airbnb && (
       <a href={al.airbnb} target="_blank" rel="noopener noreferrer">
        <Button variant="primary" onClick={handleClose} id='airbnb-btn' title='Airbnb'>
       <TbBrandAirbnb/>
      </Button>
    </a>
  )}
        </Modal.Footer>
      </Modal>
      </div>
    );
  }
  
  export default ModalCardAl;