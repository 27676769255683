import React, { useEffect, useState } from 'react';
import sanityClient from '../../client';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import { BiBath, BiBed } from 'react-icons/bi';
import { BsWifi } from 'react-icons/bs';
import { MdBalcony, MdPerson, MdPool, MdOutlineLocationOn, MdElevator, MdOutlineGarage, MdOutlineLocalLaundryService, MdPets } from 'react-icons/md';
import { Television } from "phosphor-react";
import { TbAirConditioning } from 'react-icons/tb';
import ModalCardAl from '../Modal/ModalAl';
import './CardsAl.css';

function CardsAl() {
  const [als, setAls] = useState([]);
  const [selectedAl, setSelectedAl] = useState(null); 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await sanityClient.fetch(`*[_type == 'al']{"images": images[]{..., "url": asset->url}, title, guests, rooms, baths, location, description, icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8, mapLink, booking, airbnb, cost }`);
        console.log('Al Data:', result);
        setAls(result);
      } catch (error) {
        console.error('Error fetching data from Sanity:', error);
      }
    };

    fetchData();
  }, []);

  const handleShowModal = (al) => {
    setSelectedAl(al); 
  };


  return (
    <div  className='cardsAl'>
    <div className='cards-background-al'>
    <Row xs={1} md={3} className="card-row-al">
        {als.map((al, idx) => (
          <Col key={idx}>
            <Card id='cards-al'>
               <Row>
                <Col xs={12} sm={18} md={30} lg={30}>
                    <Carousel interval={null} indicators={als.length > 1} prevIcon={null} nextIcon={null} className="carousel-container">
                      {al.images.map((image, index) => (
                        <Carousel.Item key={index}>
                          <Card.Img src={image.url} alt={al.title} className="carousel-image-al" />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    {als.length >= 1 && (
                      <div id="carousel-indicators">
                        {[...Array(al.images.length)].map((_, index) => (
                          <div key={index} className="carousel-indicator" />
                        ))}
                      </div>
                    )}
                  </Col>
                </Row>
              <Card.Body id='card-body-al'>

                <Card.Header id='card-header-al'>{al.title}</Card.Header>
                {al.guests && (
                  <Card.Text className='head-al'>
                  <MdPerson />  {al.guests} { al.guests  === 1 ? 'hóspede' : 'hóspedes'}
                  </Card.Text>
                )}  
                {al.rooms && (
                  <Card.Text className='head-al'>
                  <BiBed /> {al.rooms} {al.rooms === 1 ? 'quarto' : 'quartos'}
                  </Card.Text>
                )}  
                {al.baths && (
                  <Card.Text className='head-al'>
                  <BiBath /> {al.baths} {al.baths === 1 ? 'casa de banho' : 'casas de banho'}
                  </Card.Text>
                )}
                <Card.Text id='al-section2'>
                <Card.Header id='al-facilities'>Comodidades:</Card.Header>

                <Card.Text id='al-icons'>
                  {al.icon1 && <BsWifi className='al-icon'/>}
                  {al.icon2 && <MdPool className='al-icon'/>}
                  {al.icon9 && <Television className='al-icon'/>}
                  {al.icon3 && <TbAirConditioning className='al-icon'/>}
                  {al.icon4 && <MdElevator className='al-icon'/>}
                  {al.icon7 && <MdOutlineLocalLaundryService className='al-icon'/>}
                  {al.icon6 && <MdPets className='al-icon'/>}
                  {al.icon5 && <MdOutlineGarage className='al-icon'/>}
                  {al.icon8 && <MdBalcony className='al-icon'/>}
                </Card.Text>

                </Card.Text>
                <Card.Footer>
                <Card.Text id='card-footer-al'><MdOutlineLocationOn className='card-icon-al'/>  {al.location}</Card.Text>
                </Card.Footer>
              </Card.Body>
              <div className='btn-container'>
                <button variant="primary" className='modal-btn-al' onClick={() => handleShowModal(al)}>
                  +
                </button>               
		            </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
     {selectedAl && <ModalCardAl al={selectedAl} onClose={() => setSelectedAl(null)} />}

    </div>
  );
}

export default CardsAl;
