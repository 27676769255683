import React from 'react';
import "./Software.css"

const Software = () => {
  return (
    <div className="container">
  <h1 className="section-title">
    <div className="d-none">Contabilidade</div>
  </h1>
    <div className="row justify-content-center">
      <div className="col-6 col-md-6 col-lg-4" id='list-col'>
        <div className="categoria">
          <img
            className="img-fluid categ"
            src="https://www.filosoft.pt/app/default/assets/images/pexels-cottonbro-5054532.jpg?v=1649325868"
            alt="Faturação"
          />
          <h4 className="transition-fast">Faturação</h4>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-4" id='list-col'>
        <div className="categoria">
          <img
            className="img-fluid categ"
            src="https://www.filosoft.pt/app/default/assets/images/pexels-lukas-669615.jpg?v=1649325868"
            alt="Contabilidade"
          />
          <h4 className="transition-fast">Contabilidade</h4>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-4" id='list-col'> 
        <div className="categoria">
          <img
            className="img-fluid categ"
            src="https://www.filosoft.pt/app/default/assets/images/shutterstock_521792092.jpg?v=1649325960"
            alt="Fiscalidade"
          />
          <h4 className="transition-fast">Fiscalidade</h4>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-4" id='list-col'>
        <div className="categoria">
          <img
            className="img-fluid categ"
            src="https://www.filosoft.pt/app/default/assets/images/pexels-edmond-dantès-4342124.jpg?v=1649325869"
            alt="Recursos Humanos"
          />
          <h4 className="transition-fast">Recursos Humanos</h4>
          <div className="solucoes transition-fast row justify-content-md-center">
          </div>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-4" >
        <div className="categoria">
          <img
            className="img-fluid categ"
            src="https://www.filosoft.pt/app/default/assets/images/pexels-andrea-piacquadio-3760067.jpg?v=1649325865"
            alt="Atividades"
          />
          <h4 className="transition-fast">Atividades</h4>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-4">
        <div className="categoria">
          <img
            className="img-fluid categ"
            src="https://www.filosoft.pt/app/default/assets/images/pexels-obsahovka-obsahovka-3570238.jpg?v=1649325874"
            alt="Tesouraria"
          />
          <h4 className="transition-fast">Tesouraria</h4>
        </div>
      </div>
    </div>
   </div>
  );
};

export default Software;
